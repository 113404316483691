import * as React from "react"

const Logo = () => (
    <svg viewBox="0 0 150 150" width="150px" height="150px">
        <title>dasdasdas</title>
        <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 50.36,52.59
           C 50.40,52.64 50.42,52.71 50.42,52.83
             50.42,52.93 50.40,53.01 50.36,53.05
             50.36,53.05 49.97,53.25 49.97,53.25
             49.97,53.25 42.81,55.08 42.81,55.08
             42.81,55.08 43.02,55.73 43.02,55.73
             43.66,57.84 44.07,59.81 44.25,61.64
             44.33,62.70 44.38,63.48 44.38,64.02
             44.38,64.98 44.29,65.87 44.12,66.70
             43.99,67.50 43.73,68.22 43.34,68.88
             42.64,70.28 41.62,71.46 40.28,72.42
             38.81,73.47 37.21,74.00 35.47,74.00
             34.48,74.00 33.42,73.80 32.30,73.41
             31.60,73.19 30.97,72.75 30.41,72.09
             30.01,71.70 29.55,71.02 29.05,70.06
             28.35,68.48 28.00,66.79 28.00,65.00
             28.00,64.03 28.22,62.96 28.66,61.78
             29.56,59.86 30.96,58.34 32.84,57.25
             34.73,56.15 37.05,55.25 39.83,54.55
             39.65,53.85 39.35,52.86 38.92,51.56
             38.52,50.56 37.98,49.02 37.30,46.94
             36.60,44.94 36.10,43.07 35.80,41.34
             35.67,40.61 35.61,39.91 35.61,39.27
             35.61,38.18 35.76,37.16 36.06,36.20
             36.71,33.90 37.96,32.39 39.83,31.66
             40.73,31.22 41.73,31.00 42.81,31.00
             43.89,31.00 44.96,31.21 46.00,31.64
             46.39,31.79 46.79,32.01 47.17,32.30
             48.21,33.26 48.73,34.34 48.73,35.55
             48.73,36.07 48.62,36.66 48.41,37.31
             47.98,38.61 47.30,39.60 46.39,40.30
             46.22,40.38 46.09,40.42 46.00,40.42
             45.79,40.42 45.60,40.36 45.42,40.23
             45.20,40.10 45.13,39.93 45.22,39.72
             45.22,39.72 45.88,38.55 45.88,38.55
             46.44,37.42 46.72,36.34 46.72,35.30
             46.72,34.30 46.44,33.50 45.88,32.89
             45.53,32.45 45.10,32.23 44.58,32.23
             42.75,32.23 41.28,32.92 40.16,34.28
             39.25,35.39 38.73,36.76 38.59,38.39
             38.55,38.75 38.53,39.26 38.53,39.92
             38.53,41.20 38.68,42.40 38.98,43.50
             39.72,46.23 40.38,48.37 40.94,49.92
             41.28,50.80 41.75,52.12 42.36,53.89
             42.36,53.89 44.97,53.27 44.97,53.27
             44.97,53.27 48.02,52.48 48.02,52.48
             48.19,52.45 48.39,52.40 48.61,52.36
             48.83,52.31 49.09,52.28 49.39,52.28
             49.65,52.28 49.84,52.32 49.97,52.39
             50.05,52.39 50.18,52.46 50.36,52.59 Z
           M 41.27,63.23
           C 41.27,61.23 40.91,58.74 40.22,55.78
             37.36,56.61 35.24,57.48 33.84,58.42
             32.46,59.36 31.38,60.73 30.59,62.52
             30.25,63.48 30.08,64.41 30.08,65.31
             30.08,65.93 30.12,66.41 30.20,66.75
             30.46,67.89 30.70,68.76 30.92,69.38
             31.44,70.46 32.03,71.31 32.69,71.92
             33.29,72.48 34.00,72.77 34.83,72.77
             35.17,72.77 35.43,72.75 35.61,72.70
             36.77,72.35 37.73,71.78 38.47,71.00
             40.11,69.04 41.02,67.02 41.19,64.92
             41.24,64.58 41.27,64.02 41.27,63.23 Z
           M 53.30,66.97
           C 52.98,66.93 52.52,66.82 51.92,66.64
             49.49,65.73 48.11,63.96 47.77,61.31
             47.77,61.31 47.70,60.73 47.70,60.73
             47.70,60.73 47.70,60.08 47.70,60.08
             47.83,56.59 48.91,53.50 50.95,50.80
             51.43,50.14 51.91,49.64 52.38,49.30
             52.81,48.90 53.33,48.70 53.94,48.70
             54.33,48.70 54.68,48.77 54.98,48.89
             55.07,48.89 55.11,48.92 55.11,48.97
             55.19,49.01 55.24,49.10 55.27,49.23
             55.30,49.36 55.27,49.45 55.17,49.48
             54.09,50.45 53.23,51.54 52.61,52.78
             51.98,54.01 51.47,55.41 51.08,56.97
             50.77,57.96 50.62,59.28 50.62,60.92
             50.62,62.06 50.86,63.08 51.34,63.98
             51.64,64.55 51.99,64.98 52.38,65.28
             52.55,65.46 52.75,65.55 52.97,65.55
             53.13,65.55 53.30,65.48 53.48,65.36
             53.87,65.14 54.22,64.78 54.53,64.27
             55.00,63.32 55.26,62.78 55.31,62.66
             55.56,61.76 55.69,60.91 55.69,60.09
             55.69,58.68 55.27,57.31 54.45,55.98
             54.45,55.98 53.42,54.31 53.42,54.31
             53.38,54.27 53.36,54.19 53.36,54.06
             53.36,53.84 53.47,53.70 53.69,53.61
             53.85,53.18 55.65,52.97 59.08,52.97
             60.02,52.97 61.37,52.97 63.11,52.97
             63.58,53.12 63.92,53.34 64.14,53.64
             64.23,53.77 64.25,53.88 64.20,53.98
             64.16,54.09 64.08,54.16 63.95,54.20
             63.95,54.20 58.48,54.20 58.48,54.20
             57.75,54.20 56.82,54.25 55.69,54.33
             55.44,54.45 55.38,54.65 55.50,54.91
             55.84,55.50 56.12,55.93 56.34,56.19
             57.12,57.38 57.58,58.70 57.70,60.14
             57.80,61.96 57.45,63.64 56.67,65.16
             56.63,65.32 56.52,65.50 56.34,65.67
             55.70,66.67 54.68,67.11 53.30,66.97 Z
           M 62.06,64.94
           C 61.80,64.86 61.61,64.77 61.48,64.67
             61.05,64.46 60.81,64.17 60.77,63.78
             60.77,63.78 60.70,62.23 60.70,62.23
             60.75,61.55 60.84,60.78 60.97,59.92
             61.09,59.07 61.18,58.45 61.22,58.06
             61.87,54.46 62.55,51.37 63.30,48.80
             63.39,48.54 63.48,48.34 63.56,48.20
             63.56,48.20 63.62,48.14 63.75,48.02
             63.75,48.02 63.95,48.02 63.95,48.02
             64.17,48.02 64.38,48.09 64.59,48.25
             64.81,48.40 64.94,48.56 64.98,48.73
             64.98,48.73 64.98,49.58 64.98,49.58
             64.98,50.53 64.91,51.42 64.78,52.25
             64.66,53.09 64.57,53.61 64.53,53.83
             64.53,53.83 63.56,60.38 63.56,60.38
             63.56,60.42 63.60,60.53 63.69,60.70
             63.73,60.75 63.78,60.75 63.84,60.70
             63.91,60.65 63.97,60.59 64.02,60.50
             64.02,60.50 64.53,59.55 64.53,59.55
             64.53,59.55 65.77,56.84 65.77,56.84
             66.59,55.12 67.41,53.73 68.23,52.66
             68.37,52.45 68.80,51.94 69.55,51.12
             70.05,50.56 70.68,50.35 71.42,50.48
             71.42,50.48 72.20,50.67 72.20,50.67
             72.20,50.67 72.41,50.92 72.41,50.92
             72.41,50.98 72.36,51.04 72.27,51.12
             72.18,51.21 72.14,51.25 72.14,51.25
             71.54,51.29 70.95,51.68 70.39,52.41
             69.39,53.56 68.52,54.91 67.78,56.45
             67.26,57.48 66.89,58.26 66.69,58.77
             66.69,58.77 65.91,60.44 65.91,60.44
             65.91,60.44 64.67,63.20 64.67,63.20
             64.32,63.80 64.10,64.14 64.02,64.23
             63.63,64.75 63.20,65.00 62.72,65.00
             62.46,65.00 62.24,64.98 62.06,64.94 Z
           M 80.47,66.16
           C 79.56,65.77 78.91,65.11 78.53,64.16
             78.39,63.81 78.33,63.28 78.33,62.55
             78.37,61.98 78.45,61.39 78.58,60.75
             78.71,60.11 78.80,59.65 78.84,59.39
             78.84,59.39 78.92,58.81 78.92,58.81
             78.92,58.81 78.85,58.75 78.72,58.61
             78.72,58.61 78.59,58.61 78.59,58.61
             78.24,58.96 78.06,59.14 78.06,59.14
             76.77,60.55 76.14,61.23 76.19,61.19
             76.19,61.19 75.73,61.57 74.81,62.34
             74.56,62.56 74.20,62.67 73.72,62.67
             73.06,62.67 72.45,62.52 71.89,62.22
             70.98,61.75 70.51,61.04 70.47,60.09
             70.47,59.45 70.49,58.98 70.53,58.67
             70.75,57.38 71.12,56.24 71.64,55.25
             71.64,55.25 73.19,52.67 73.19,52.67
             73.50,52.15 73.98,51.62 74.62,51.06
             75.41,50.50 76.23,50.22 77.09,50.22
             77.74,50.22 78.41,50.40 79.11,50.75
             79.55,51.01 79.96,51.45 80.34,52.06
             80.34,52.06 80.67,52.78 80.67,52.78
             80.71,52.78 80.75,52.79 80.80,52.81
             80.84,52.84 80.88,52.82 80.94,52.78
             80.94,52.78 81.39,52.39 81.39,52.39
             81.39,52.39 83.33,49.58 83.33,49.58
             83.33,49.58 84.11,48.45 84.11,48.45
             84.29,48.15 84.55,48.00 84.89,48.00
             85.15,48.00 85.37,48.09 85.55,48.27
             85.80,48.39 85.91,48.61 85.88,48.91
             85.88,49.03 85.83,49.23 85.73,49.48
             85.21,50.86 84.80,51.90 84.50,52.59
             84.20,53.41 83.70,54.63 83.02,56.27
             82.40,57.78 81.86,59.82 81.39,62.41
             81.30,63.11 81.25,63.61 81.25,63.91
             81.25,64.20 81.27,64.41 81.31,64.55
             81.36,64.63 81.45,64.71 81.58,64.78
             81.71,64.84 81.82,64.85 81.91,64.80
             82.12,64.67 82.54,64.38 83.14,63.91
             83.92,63.12 84.66,62.22 85.34,61.19
             87.51,58.04 89.09,55.56 90.09,53.75
             90.23,53.41 90.43,53.11 90.69,52.84
             90.77,52.72 90.89,52.66 91.06,52.66
             91.24,52.66 91.40,52.70 91.55,52.78
             91.70,52.87 91.80,52.98 91.84,53.11
             91.84,53.15 91.85,53.20 91.88,53.23
             91.91,53.28 91.89,53.32 91.84,53.36
             91.80,53.58 91.70,53.86 91.53,54.20
             91.53,54.20 89.77,57.23 89.77,57.23
             89.77,57.23 87.05,61.50 87.05,61.50
             86.35,62.50 85.68,63.43 85.03,64.28
             84.41,64.98 83.94,65.45 83.59,65.70
             83.03,66.23 82.43,66.48 81.78,66.48
             81.43,66.48 80.99,66.38 80.47,66.16 Z
           M 73.72,60.48
           C 73.94,60.48 74.07,60.46 74.11,60.42
             74.11,60.42 75.34,59.52 75.34,59.52
             76.77,58.18 78.35,56.36 80.08,54.03
             80.17,53.95 80.22,53.91 80.22,53.91
             80.26,53.82 80.26,53.73 80.22,53.62
             80.18,53.51 80.11,53.43 80.02,53.39
             79.49,53.17 79.21,52.85 79.17,52.42
             79.13,52.30 79.02,52.11 78.84,51.84
             78.72,51.59 78.52,51.45 78.27,51.45
             78.09,51.45 77.89,51.52 77.69,51.64
             77.07,51.98 76.55,52.53 76.12,53.27
             75.08,54.81 74.30,56.48 73.78,58.30
             73.78,58.30 73.45,59.84 73.45,59.84
             73.45,60.19 73.54,60.40 73.72,60.48 Z
           M 97.94,69.48
           C 97.24,69.48 96.65,69.27 96.17,68.84
             95.87,68.68 95.68,68.38 95.59,67.95
             95.59,67.95 95.53,66.98 95.53,66.98
             95.57,65.79 95.72,64.65 95.98,63.58
             96.24,62.51 96.39,61.88 96.44,61.70
             96.91,59.90 97.72,57.24 98.84,53.72
             98.84,53.72 99.23,52.30 99.23,52.30
             99.23,52.30 99.17,52.17 99.17,52.17
             99.17,52.17 98.91,52.17 98.91,52.17
             98.82,52.26 98.73,52.38 98.64,52.55
             98.64,52.55 95.98,57.31 95.98,57.31
             95.98,57.31 92.92,62.58 92.92,62.58
             92.70,63.01 92.44,63.37 92.14,63.67
             91.84,64.10 91.43,64.31 90.91,64.31
             90.69,64.31 90.41,64.25 90.06,64.12
             90.06,64.12 89.81,64.05 89.81,64.05
             88.94,63.66 88.57,63.00 88.70,62.05
             88.79,60.92 88.87,60.06 88.95,59.45
             89.31,57.04 89.81,54.92 90.45,53.11
             90.45,53.11 91.05,51.56 91.05,51.56
             91.13,51.34 91.23,51.17 91.36,51.05
             91.58,50.83 91.82,50.74 92.08,50.78
             92.08,50.78 92.47,50.98 92.47,50.98
             92.81,51.15 92.96,51.43 92.92,51.81
             92.84,52.12 92.74,52.50 92.62,52.95
             92.52,53.40 92.43,53.91 92.34,54.47
             92.12,55.42 91.77,57.21 91.30,59.84
             91.25,60.02 91.23,60.28 91.23,60.62
             91.23,60.75 91.25,60.87 91.30,60.98
             91.34,61.09 91.41,61.12 91.50,61.08
             91.50,61.08 91.81,60.88 91.81,60.88
             92.99,59.20 93.92,57.73 94.61,56.48
             94.61,56.48 97.73,50.72 97.73,50.72
             98.21,49.86 98.80,49.08 99.48,48.39
             99.74,48.13 100.00,48.00 100.27,48.00
             100.52,48.00 100.72,48.05 100.86,48.14
             100.86,48.14 101.25,48.34 101.25,48.34
             101.63,48.57 101.87,48.99 101.95,49.59
             101.95,50.24 101.89,50.89 101.77,51.53
             101.77,51.53 100.20,57.52 100.20,57.52
             99.30,61.12 98.69,63.67 98.39,65.17
             98.39,65.26 98.35,65.47 98.28,65.81
             98.22,66.16 98.23,66.46 98.31,66.72
             98.31,66.72 98.39,66.84 98.39,66.84
             98.55,66.84 98.64,66.82 98.64,66.78
             98.86,66.61 99.14,66.30 99.48,65.88
             100.92,63.87 102.07,62.09 102.94,60.53
             103.80,59.17 104.43,58.14 104.81,57.45
             105.16,56.85 105.64,55.95 106.25,54.75
             106.25,54.75 106.91,53.52 106.91,53.52
             107.07,53.21 107.38,53.13 107.81,53.27
             107.98,53.31 108.11,53.40 108.22,53.53
             108.33,53.66 108.35,53.78 108.27,53.91
             108.13,54.21 107.88,54.73 107.52,55.48
             107.15,56.23 106.82,56.89 106.52,57.45
             106.52,57.45 101.38,66.27 101.38,66.27
             101.38,66.27 100.20,68.00 100.20,68.00
             99.94,68.39 99.53,68.77 98.97,69.16
             98.62,69.38 98.28,69.48 97.94,69.48 Z
           M 113.47,66.16
           C 112.56,65.77 111.91,65.11 111.53,64.16
             111.39,63.81 111.33,63.28 111.33,62.55
             111.37,61.98 111.45,61.39 111.58,60.75
             111.71,60.11 111.80,59.65 111.84,59.39
             111.84,59.39 111.92,58.81 111.92,58.81
             111.92,58.81 111.85,58.75 111.72,58.61
             111.72,58.61 111.59,58.61 111.59,58.61
             111.24,58.96 111.06,59.14 111.06,59.14
             109.77,60.55 109.14,61.23 109.19,61.19
             109.19,61.19 108.73,61.57 107.81,62.34
             107.56,62.56 107.20,62.67 106.72,62.67
             106.06,62.67 105.45,62.52 104.89,62.22
             103.98,61.75 103.51,61.04 103.47,60.09
             103.47,59.45 103.49,58.98 103.53,58.67
             103.75,57.38 104.12,56.24 104.64,55.25
             104.64,55.25 106.19,52.67 106.19,52.67
             106.50,52.15 106.98,51.62 107.62,51.06
             108.41,50.50 109.23,50.22 110.09,50.22
             110.74,50.22 111.41,50.40 112.11,50.75
             112.55,51.01 112.96,51.45 113.34,52.06
             113.34,52.06 113.67,52.78 113.67,52.78
             113.71,52.78 113.75,52.79 113.80,52.81
             113.84,52.84 113.88,52.82 113.94,52.78
             113.94,52.78 114.39,52.39 114.39,52.39
             114.39,52.39 116.33,49.58 116.33,49.58
             116.33,49.58 117.11,48.45 117.11,48.45
             117.29,48.15 117.55,48.00 117.89,48.00
             118.15,48.00 118.37,48.09 118.55,48.27
             118.80,48.39 118.91,48.61 118.88,48.91
             118.88,49.03 118.83,49.23 118.73,49.48
             118.21,50.86 117.80,51.90 117.50,52.59
             117.20,53.41 116.70,54.63 116.02,56.27
             115.40,57.78 114.86,59.82 114.39,62.41
             114.30,63.11 114.25,63.61 114.25,63.91
             114.25,64.20 114.27,64.41 114.31,64.55
             114.36,64.63 114.45,64.71 114.58,64.78
             114.71,64.84 114.82,64.85 114.91,64.80
             115.12,64.67 115.54,64.38 116.14,63.91
             116.92,63.12 117.66,62.22 118.34,61.19
             120.51,58.04 122.09,55.56 123.09,53.75
             123.23,53.41 123.43,53.11 123.69,52.84
             123.77,52.72 123.89,52.66 124.06,52.66
             124.24,52.66 124.40,52.70 124.55,52.78
             124.70,52.87 124.80,52.98 124.84,53.11
             124.84,53.15 124.85,53.20 124.88,53.23
             124.91,53.28 124.89,53.32 124.84,53.36
             124.80,53.58 124.70,53.86 124.53,54.20
             124.53,54.20 122.77,57.23 122.77,57.23
             122.77,57.23 120.05,61.50 120.05,61.50
             119.35,62.50 118.68,63.43 118.03,64.28
             117.41,64.98 116.94,65.45 116.59,65.70
             116.03,66.23 115.43,66.48 114.78,66.48
             114.43,66.48 113.99,66.38 113.47,66.16 Z
           M 106.72,60.48
           C 106.94,60.48 107.07,60.46 107.11,60.42
             107.11,60.42 108.34,59.52 108.34,59.52
             109.77,58.18 111.35,56.36 113.08,54.03
             113.17,53.95 113.22,53.91 113.22,53.91
             113.26,53.82 113.26,53.73 113.22,53.62
             113.18,53.51 113.11,53.43 113.02,53.39
             112.49,53.17 112.21,52.85 112.17,52.42
             112.13,52.30 112.02,52.11 111.84,51.84
             111.72,51.59 111.52,51.45 111.27,51.45
             111.09,51.45 110.89,51.52 110.69,51.64
             110.07,51.98 109.55,52.53 109.12,53.27
             108.08,54.81 107.30,56.48 106.78,58.30
             106.78,58.30 106.45,59.84 106.45,59.84
             106.45,60.19 106.54,60.40 106.72,60.48 Z
           M 39.66,112.61
           C 39.70,112.65 39.72,112.73 39.72,112.84
             39.72,112.95 39.70,113.02 39.66,113.06
             39.66,113.06 39.27,113.25 39.27,113.25
             37.10,113.81 35.11,114.45 33.30,115.17
             33.30,115.17 30.36,124.22 30.36,124.22
             29.84,125.62 29.21,127.27 28.48,129.16
             27.74,131.07 26.72,132.93 25.42,134.72
             24.77,135.58 24.02,136.33 23.16,136.97
             22.16,137.78 20.91,138.19 19.44,138.19
             18.41,138.19 17.58,137.76 16.97,136.91
             16.58,136.39 16.32,135.86 16.19,135.31
             16.06,134.76 16.00,134.08 16.00,133.27
             16.00,132.03 16.24,130.77 16.72,129.50
             17.24,128.30 17.71,127.26 18.14,126.36
             19.52,124.23 21.28,122.21 23.41,120.30
             25.53,118.37 27.85,116.75 30.36,115.42
             30.36,115.42 31.53,111.78 31.53,111.78
             31.83,110.76 32.33,109.23 33.03,107.19
             33.03,107.19 33.36,106.03 33.36,106.03
             33.36,105.95 33.31,105.91 33.22,105.91
             33.13,105.91 33.09,105.93 33.09,105.97
             32.88,106.09 32.70,106.27 32.58,106.48
             31.36,107.95 30.47,109.06 29.91,109.83
             28.70,111.37 27.09,112.98 25.09,114.66
             24.01,115.69 22.69,116.20 21.14,116.20
             20.44,116.20 19.66,116.10 18.80,115.89
             17.45,115.51 16.63,114.66 16.33,113.36
             16.19,112.73 16.10,112.04 16.06,111.30
             16.02,110.56 16.00,110.09 16.00,109.88
             16.00,106.97 16.69,103.95 18.08,100.83
             19.12,98.38 20.31,96.21 21.66,94.31
             22.60,92.84 23.82,91.42 25.30,90.06
             26.46,88.93 27.80,88.04 29.33,87.41
             29.97,87.12 30.69,86.97 31.47,86.97
             32.59,86.97 33.70,87.32 34.78,88.03
             35.70,88.57 36.16,89.37 36.16,90.41
             36.16,90.41 36.08,91.00 36.08,91.00
             35.87,92.17 35.52,93.07 35.05,93.72
             34.79,94.07 34.44,94.18 34.00,94.05
             33.83,94.01 33.71,93.92 33.64,93.80
             33.58,93.66 33.57,93.51 33.61,93.33
             33.61,93.33 34.20,91.77 34.20,91.77
             34.46,91.20 34.55,90.55 34.45,89.81
             34.45,89.76 34.39,89.47 34.27,88.94
             34.05,88.49 33.72,88.25 33.30,88.20
             33.30,88.20 32.91,88.20 32.91,88.20
             32.47,88.20 32.10,88.29 31.80,88.45
             31.80,88.45 30.36,89.22 30.36,89.22
             29.11,90.06 28.05,90.99 27.19,92.00
             25.49,93.86 23.93,96.11 22.50,98.77
             21.11,101.26 20.09,103.79 19.44,106.36
             19.13,108.05 18.98,109.55 18.98,110.86
             18.98,110.86 19.06,112.31 19.06,112.31
             19.06,112.91 19.21,113.59 19.52,114.34
             19.68,114.68 20.00,114.89 20.48,114.97
             20.96,114.97 21.46,114.87 21.98,114.66
             22.67,114.36 23.23,114.02 23.67,113.64
             25.80,112.04 27.44,110.50 28.61,109.02
             29.21,108.26 29.82,107.54 30.44,106.86
             30.82,106.39 31.44,105.63 32.28,104.58
             33.12,103.52 33.85,102.56 34.45,101.72
             35.02,101.04 35.48,100.39 35.83,99.77
             36.05,99.30 36.33,99.06 36.67,99.06
             36.85,99.06 37.06,99.12 37.31,99.25
             37.31,99.25 37.58,99.44 37.58,99.44
             37.80,99.56 37.91,99.78 37.91,100.08
             37.91,100.25 37.88,100.39 37.84,100.52
             37.41,102.37 37.05,103.77 36.80,104.70
             36.80,104.70 33.81,113.75 33.81,113.75
             34.98,113.34 36.14,112.95 37.31,112.61
             37.49,112.57 37.69,112.53 37.91,112.48
             38.12,112.45 38.38,112.42 38.69,112.42
             38.95,112.42 39.14,112.45 39.27,112.48
             39.36,112.48 39.49,112.53 39.66,112.61 Z
           M 29.91,116.67
           C 27.88,117.80 26.25,118.95 25.03,120.12
             23.82,121.29 22.90,122.28 22.27,123.08
             21.64,123.88 21.24,124.39 21.06,124.61
             19.55,126.60 18.54,128.75 18.02,131.05
             17.80,131.82 17.73,132.77 17.81,133.91
             17.81,134.77 18.10,135.59 18.67,136.38
             18.88,136.63 19.11,136.87 19.38,137.08
             19.77,137.34 20.16,137.38 20.55,137.22
             20.72,137.12 21.02,136.93 21.45,136.62
             22.15,135.98 22.72,135.33 23.16,134.67
             24.24,133.02 25.16,131.17 25.94,129.09
             26.59,127.27 27.33,125.04 28.16,122.39
             28.16,122.39 29.91,116.67 29.91,116.67 Z
           M 37.03,127.69
           C 36.29,127.34 35.84,126.84 35.66,126.16
             35.66,126.16 35.47,125.14 35.47,125.14
             35.43,124.81 35.41,124.34 35.41,123.75
             35.41,120.42 36.18,117.27 37.73,114.31
             38.35,113.21 39.00,112.32 39.69,111.64
             39.99,111.31 40.40,110.95 40.92,110.58
             41.52,110.20 42.16,110.00 42.81,110.00
             42.81,110.00 43.00,110.00 43.00,110.00
             43.35,110.00 43.79,110.10 44.31,110.30
             45.26,110.66 45.73,111.27 45.73,112.11
             45.73,112.37 45.67,112.68 45.55,113.03
             45.33,113.87 44.85,114.73 44.11,115.61
             43.41,116.36 42.50,117.09 41.38,117.78
             40.99,117.96 40.45,118.14 39.75,118.31
             39.36,118.36 39.10,118.62 38.97,119.11
             38.97,119.11 38.58,120.77 38.58,120.77
             38.32,122.48 38.21,124.08 38.27,125.58
             38.27,125.58 38.27,126.05 38.27,126.05
             38.35,126.53 38.52,126.77 38.78,126.77
             38.96,126.77 39.15,126.68 39.36,126.50
             39.88,126.11 40.36,125.68 40.80,125.23
             41.96,124.00 42.85,122.89 43.45,121.91
             44.15,120.94 44.65,120.21 44.95,119.72
             44.95,119.72 48.86,113.73 48.86,113.73
             48.90,113.64 48.94,113.55 48.98,113.47
             49.02,113.38 49.09,113.29 49.19,113.20
             49.31,113.07 49.48,113.00 49.70,113.00
             49.96,113.00 50.16,113.09 50.28,113.25
             50.28,113.25 50.42,113.52 50.42,113.52
             50.42,113.64 50.38,113.79 50.28,113.97
             50.02,114.48 49.80,114.87 49.64,115.12
             48.25,117.15 46.80,119.36 45.28,121.75
             44.50,122.92 43.50,124.25 42.30,125.75
             41.68,126.40 41.03,126.98 40.34,127.48
             39.82,127.83 39.21,128.00 38.52,128.00
             37.95,128.00 37.46,127.90 37.03,127.69 Z
           M 39.95,116.88
           C 40.04,116.96 40.12,116.96 40.20,116.88
             40.55,116.75 40.91,116.55 41.25,116.27
             41.59,115.98 41.83,115.77 41.97,115.64
             42.75,115.00 43.38,114.11 43.84,112.97
             44.02,112.62 44.11,112.24 44.11,111.81
             44.11,111.81 44.11,111.67 44.11,111.67
             44.07,111.45 43.96,111.31 43.78,111.25
             43.61,111.19 43.44,111.20 43.27,111.28
             42.88,111.50 42.47,111.87 42.03,112.39
             40.99,113.73 40.25,115.10 39.81,116.48
             39.73,116.70 39.77,116.84 39.95,116.88 Z
           M 52.30,126.97
           C 51.98,126.93 51.52,126.82 50.92,126.64
             48.49,125.73 47.11,123.96 46.77,121.31
             46.77,121.31 46.70,120.73 46.70,120.73
             46.70,120.73 46.70,120.08 46.70,120.08
             46.83,116.59 47.91,113.50 49.95,110.80
             50.43,110.14 50.91,109.64 51.38,109.30
             51.81,108.90 52.33,108.70 52.94,108.70
             53.33,108.70 53.68,108.77 53.98,108.89
             54.07,108.89 54.11,108.92 54.11,108.97
             54.19,109.01 54.24,109.10 54.27,109.23
             54.30,109.36 54.27,109.45 54.17,109.48
             53.09,110.45 52.23,111.54 51.61,112.78
             50.98,114.01 50.47,115.41 50.08,116.97
             49.77,117.96 49.62,119.28 49.62,120.92
             49.62,122.06 49.86,123.08 50.34,123.98
             50.64,124.55 50.99,124.98 51.38,125.28
             51.55,125.46 51.75,125.55 51.97,125.55
             52.13,125.55 52.30,125.48 52.48,125.36
             52.87,125.14 53.22,124.78 53.53,124.27
             54.00,123.32 54.26,122.78 54.31,122.66
             54.56,121.76 54.69,120.91 54.69,120.09
             54.69,118.68 54.27,117.31 53.45,115.98
             53.45,115.98 52.42,114.31 52.42,114.31
             52.38,114.27 52.36,114.19 52.36,114.06
             52.36,113.84 52.47,113.70 52.69,113.61
             52.85,113.18 54.65,112.97 58.08,112.97
             59.02,112.97 60.37,112.97 62.11,112.97
             62.58,113.12 62.92,113.34 63.14,113.64
             63.23,113.77 63.25,113.88 63.20,113.98
             63.16,114.09 63.08,114.16 62.95,114.20
             62.95,114.20 57.48,114.20 57.48,114.20
             56.75,114.20 55.82,114.25 54.69,114.33
             54.44,114.45 54.38,114.65 54.50,114.91
             54.84,115.50 55.12,115.93 55.34,116.19
             56.12,117.38 56.58,118.70 56.70,120.14
             56.80,121.96 56.45,123.64 55.67,125.16
             55.63,125.32 55.52,125.50 55.34,125.67
             54.70,126.67 53.68,127.11 52.30,126.97 Z
           M 61.06,124.94
           C 60.80,124.86 60.61,124.77 60.48,124.67
             60.05,124.46 59.81,124.17 59.77,123.78
             59.77,123.78 59.70,122.23 59.70,122.23
             59.75,121.55 59.84,120.78 59.97,119.92
             60.09,119.07 60.18,118.45 60.22,118.06
             60.87,114.46 61.55,111.37 62.30,108.80
             62.39,108.54 62.48,108.34 62.56,108.20
             62.56,108.20 62.62,108.14 62.75,108.02
             62.75,108.02 62.95,108.02 62.95,108.02
             63.17,108.02 63.38,108.09 63.59,108.25
             63.81,108.40 63.94,108.56 63.98,108.73
             63.98,108.73 63.98,109.58 63.98,109.58
             63.98,110.53 63.91,111.42 63.78,112.25
             63.66,113.09 63.57,113.61 63.53,113.83
             63.53,113.83 62.56,120.38 62.56,120.38
             62.56,120.42 62.60,120.53 62.69,120.70
             62.73,120.75 62.78,120.75 62.84,120.70
             62.91,120.65 62.97,120.59 63.02,120.50
             63.02,120.50 63.53,119.55 63.53,119.55
             63.53,119.55 64.77,116.84 64.77,116.84
             65.59,115.12 66.41,113.73 67.23,112.66
             67.37,112.45 67.80,111.94 68.55,111.12
             69.05,110.56 69.68,110.35 70.42,110.48
             70.42,110.48 71.20,110.67 71.20,110.67
             71.20,110.67 71.41,110.92 71.41,110.92
             71.41,110.98 71.36,111.04 71.27,111.12
             71.18,111.21 71.14,111.25 71.14,111.25
             70.54,111.29 69.95,111.68 69.39,112.41
             68.39,113.56 67.52,114.91 66.78,116.45
             66.26,117.48 65.89,118.26 65.69,118.77
             65.69,118.77 64.91,120.44 64.91,120.44
             64.91,120.44 63.67,123.20 63.67,123.20
             63.32,123.80 63.10,124.14 63.02,124.23
             62.63,124.75 62.20,125.00 61.72,125.00
             61.46,125.00 61.24,124.98 61.06,124.94 Z
           M 70.11,142.88
           C 69.59,142.74 69.23,142.41 69.02,141.91
             68.84,141.31 68.75,140.84 68.75,140.50
             68.75,140.28 68.77,140.11 68.81,139.98
             68.99,138.74 69.32,137.59 69.80,136.52
             70.96,133.94 72.56,131.44 74.59,129.00
             74.59,129.00 76.16,127.14 76.16,127.14
             76.51,126.76 76.79,126.22 77.00,125.53
             77.00,125.53 77.84,122.52 77.84,122.52
             78.10,121.45 78.64,119.66 79.47,117.12
             79.47,117.12 79.86,115.84 79.86,115.84
             79.86,115.84 79.73,115.58 79.73,115.58
             79.73,115.58 79.61,115.58 79.61,115.58
             79.61,115.58 78.89,116.17 78.89,116.17
             78.89,116.17 78.46,116.61 77.59,117.47
             77.59,117.47 75.58,119.38 75.58,119.38
             75.10,119.80 74.58,120.20 74.02,120.55
             73.58,120.85 73.08,121.00 72.52,121.00
             72.00,121.00 71.43,120.83 70.83,120.48
             70.31,120.23 70.01,119.77 69.92,119.11
             69.92,118.58 69.95,118.18 69.98,117.92
             70.42,115.77 71.48,113.90 73.17,112.31
             73.17,112.31 73.50,112.06 73.50,112.06
             74.36,111.36 75.32,111.00 76.36,111.00
             77.30,111.00 78.24,111.34 79.16,112.03
             79.16,112.03 79.61,112.45 79.61,112.45
             79.73,112.54 79.88,112.54 80.06,112.45
             80.06,112.45 80.91,111.70 80.91,111.70
             80.91,111.70 81.88,110.78 81.88,110.78
             82.09,110.59 82.40,110.50 82.80,110.50
             83.05,110.50 83.27,110.54 83.44,110.62
             83.70,110.79 83.83,111.01 83.83,111.27
             83.83,111.43 83.74,111.81 83.56,112.41
             83.56,112.41 81.56,119.16 81.56,119.16
             81.47,119.46 81.38,119.95 81.30,120.62
             81.30,120.62 81.36,120.69 81.36,120.69
             81.44,120.73 81.51,120.73 81.56,120.69
             81.56,120.69 82.02,120.36 82.02,120.36
             82.02,120.36 83.50,118.53 83.50,118.53
             84.20,117.75 85.20,116.53 86.50,114.88
             86.50,114.88 86.89,114.42 87.67,113.50
             87.84,113.34 88.07,113.25 88.38,113.25
             88.73,113.25 89.01,113.36 89.22,113.56
             89.35,113.65 89.38,113.73 89.30,113.81
             89.24,113.99 89.13,114.21 88.97,114.47
             88.97,114.47 86.44,117.55 86.44,117.55
             86.44,117.55 84.73,119.55 84.73,119.55
             84.73,119.55 80.91,123.97 80.91,123.97
             80.30,124.66 79.85,125.56 79.55,126.67
             79.55,126.67 77.72,133.69 77.72,133.69
             77.16,135.91 76.44,137.83 75.58,139.47
             74.92,140.58 74.23,141.46 73.50,142.09
             72.80,142.70 71.93,143.00 70.89,143.00
             70.89,143.00 70.11,142.88 70.11,142.88 Z
           M 74.20,117.72
           C 75.72,116.55 77.07,115.32 78.23,114.02
             78.23,114.02 78.44,113.83 78.44,113.83
             78.48,113.73 78.50,113.62 78.50,113.50
             78.50,113.50 78.50,113.38 78.50,113.38
             78.32,112.94 78.17,112.61 78.05,112.39
             77.96,112.23 77.82,112.11 77.62,112.05
             77.43,111.98 77.22,111.98 77.00,112.06
             76.39,112.42 75.98,112.73 75.77,112.98
             74.52,114.37 73.67,115.87 73.23,117.47
             73.19,117.55 73.17,117.70 73.17,117.92
             73.17,117.96 73.22,118.02 73.33,118.09
             73.44,118.16 73.52,118.16 73.56,118.11
             73.56,118.11 74.20,117.72 74.20,117.72 Z
           M 71.09,141.50
           C 71.18,141.50 71.31,141.46 71.48,141.38
             71.70,141.16 71.81,141.06 71.81,141.06
             72.28,140.47 72.71,139.73 73.11,138.84
             73.58,137.82 74.00,136.68 74.39,135.41
             74.79,134.14 75.13,132.90 75.44,131.67
             75.44,131.63 75.45,131.58 75.47,131.52
             75.50,131.45 75.49,131.40 75.44,131.36
             75.44,131.23 75.41,131.15 75.38,131.11
             75.21,131.11 75.10,131.13 75.06,131.17
             73.46,133.20 72.16,135.42 71.16,137.83
             70.86,138.80 70.70,139.62 70.70,140.30
             70.70,140.63 70.73,140.88 70.77,141.06
             70.77,141.35 70.88,141.50 71.09,141.50 Z
           M 86.03,127.69
           C 85.29,127.34 84.84,126.84 84.66,126.16
             84.66,126.16 84.47,125.14 84.47,125.14
             84.43,124.81 84.41,124.34 84.41,123.75
             84.41,120.42 85.18,117.27 86.73,114.31
             87.35,113.21 88.00,112.32 88.69,111.64
             88.99,111.31 89.40,110.95 89.92,110.58
             90.52,110.20 91.16,110.00 91.81,110.00
             91.81,110.00 92.00,110.00 92.00,110.00
             92.35,110.00 92.79,110.10 93.31,110.30
             94.26,110.66 94.73,111.27 94.73,112.11
             94.73,112.37 94.67,112.68 94.55,113.03
             94.33,113.87 93.85,114.73 93.11,115.61
             92.41,116.36 91.50,117.09 90.38,117.78
             89.99,117.96 89.45,118.14 88.75,118.31
             88.36,118.36 88.10,118.62 87.97,119.11
             87.97,119.11 87.58,120.77 87.58,120.77
             87.32,122.48 87.21,124.08 87.27,125.58
             87.27,125.58 87.27,126.05 87.27,126.05
             87.35,126.53 87.52,126.77 87.78,126.77
             87.96,126.77 88.15,126.68 88.36,126.50
             88.88,126.11 89.36,125.68 89.80,125.23
             90.96,124.00 91.85,122.89 92.45,121.91
             93.15,120.94 93.65,120.21 93.95,119.72
             93.95,119.72 97.86,113.73 97.86,113.73
             97.90,113.64 97.94,113.55 97.98,113.47
             98.02,113.38 98.09,113.29 98.19,113.20
             98.31,113.07 98.48,113.00 98.70,113.00
             98.96,113.00 99.16,113.09 99.28,113.25
             99.28,113.25 99.42,113.52 99.42,113.52
             99.42,113.64 99.38,113.79 99.28,113.97
             99.02,114.48 98.80,114.87 98.64,115.12
             97.25,117.15 95.80,119.36 94.28,121.75
             93.50,122.92 92.50,124.25 91.30,125.75
             90.68,126.40 90.03,126.98 89.34,127.48
             88.82,127.83 88.21,128.00 87.52,128.00
             86.95,128.00 86.46,127.90 86.03,127.69 Z
           M 88.95,116.88
           C 89.04,116.96 89.12,116.96 89.20,116.88
             89.55,116.75 89.91,116.55 90.25,116.27
             90.59,115.98 90.83,115.77 90.97,115.64
             91.75,115.00 92.38,114.11 92.84,112.97
             93.02,112.62 93.11,112.24 93.11,111.81
             93.11,111.81 93.11,111.67 93.11,111.67
             93.07,111.45 92.96,111.31 92.78,111.25
             92.61,111.19 92.44,111.20 92.27,111.28
             91.88,111.50 91.47,111.87 91.03,112.39
             89.99,113.73 89.25,115.10 88.81,116.48
             88.73,116.70 88.77,116.84 88.95,116.88 Z
           M 97.12,132.81
           C 96.48,132.61 95.98,132.25 95.61,131.75
             95.23,131.24 95.05,130.69 95.05,130.09
             95.05,129.88 95.09,129.60 95.19,129.27
             95.19,129.27 95.83,127.72 95.83,127.72
             96.79,126.01 97.94,124.34 99.28,122.72
             99.54,122.43 99.74,122.19 99.89,122.00
             100.05,121.80 100.12,121.64 100.12,121.50
             100.12,121.34 99.99,120.91 99.73,120.22
             98.82,117.92 98.16,116.09 97.78,114.72
             97.52,113.73 97.39,112.75 97.39,111.77
             97.39,110.95 97.43,110.34 97.52,109.91
             97.69,108.62 98.30,107.56 99.34,106.70
             99.95,106.23 100.55,106.00 101.16,106.00
             101.55,106.00 101.90,106.07 102.20,106.20
             102.55,106.26 102.89,106.40 103.23,106.62
             104.11,107.00 104.61,107.77 104.73,108.94
             104.73,109.54 104.71,110.00 104.67,110.31
             104.45,111.74 104.10,113.02 103.62,114.14
             103.62,114.14 103.44,114.53 103.44,114.53
             103.26,114.97 102.91,115.12 102.39,114.98
             102.00,114.90 101.85,114.68 101.94,114.33
             102.02,113.73 102.11,113.27 102.20,112.97
             102.50,112.02 102.72,110.88 102.84,109.53
             102.89,109.44 102.92,109.29 102.92,109.08
             102.92,108.73 102.81,108.29 102.59,107.77
             102.50,107.68 102.38,107.62 102.25,107.58
             102.12,107.54 102.02,107.54 101.94,107.58
             101.16,107.96 100.57,108.70 100.19,109.78
             99.88,110.46 99.77,111.21 99.86,112.05
             99.86,112.05 99.86,112.48 99.86,112.48
             99.86,113.12 99.93,113.73 100.06,114.33
             100.45,115.89 101.07,117.58 101.94,119.39
             102.02,119.52 102.11,119.59 102.22,119.61
             102.33,119.63 102.44,119.60 102.53,119.52
             102.53,119.52 103.44,118.77 103.44,118.77
             103.44,118.77 107.33,114.95 107.33,114.95
             107.33,114.95 108.89,113.50 108.89,113.50
             109.07,113.34 109.27,113.25 109.48,113.25
             109.70,113.25 109.91,113.31 110.12,113.44
             110.43,113.56 110.47,113.78 110.27,114.08
             110.27,114.08 109.67,114.70 109.67,114.70
             109.67,114.70 103.77,120.34 103.77,120.34
             103.67,120.47 103.56,120.57 103.44,120.66
             103.09,120.95 102.92,121.31 102.92,121.73
             102.92,121.95 102.94,122.11 102.98,122.23
             103.29,123.12 103.44,124.07 103.44,125.09
             103.44,125.98 103.33,126.95 103.11,128.00
             102.80,129.77 101.88,131.20 100.31,132.30
             99.62,132.77 98.95,133.00 98.30,133.00
             97.91,133.00 97.52,132.94 97.12,132.81 Z
           M 98.36,131.75
           C 99.27,130.89 99.91,129.87 100.25,128.70
             100.51,127.80 100.64,126.93 100.64,126.11
             100.68,125.89 100.70,125.59 100.70,125.20
             100.70,124.86 100.66,124.62 100.58,124.50
             100.58,124.41 100.53,124.32 100.44,124.23
             100.35,124.20 100.28,124.18 100.22,124.20
             100.16,124.23 100.10,124.28 100.06,124.36
             98.93,125.65 97.99,127.14 97.27,128.83
             97.09,129.27 97.00,129.62 97.00,129.88
             97.00,129.88 97.00,130.20 97.00,130.20
             97.00,130.73 97.15,131.20 97.45,131.62
             97.71,131.97 98.02,132.01 98.36,131.75 Z
           M 110.08,127.00
           C 109.17,126.92 108.46,126.70 107.94,126.36
             107.54,126.07 107.28,125.71 107.16,125.28
             106.86,124.44 106.70,123.25 106.70,121.72
             106.92,118.46 107.70,115.28 109.05,112.17
             109.73,110.61 110.66,109.12 111.83,107.72
             112.22,107.22 112.72,106.71 113.33,106.20
             113.93,105.73 114.54,105.50 115.16,105.50
             115.93,105.50 116.68,105.81 117.42,106.44
             117.82,106.71 118.02,107.11 118.02,107.64
             118.14,109.45 117.77,111.11 116.91,112.62
             116.64,113.06 116.25,113.21 115.73,113.08
             115.65,113.04 115.56,112.96 115.47,112.86
             115.38,112.75 115.36,112.67 115.41,112.62
             115.41,112.62 115.80,111.45 115.80,111.45
             116.19,110.25 116.36,109.03 116.31,107.81
             116.31,107.65 116.29,107.50 116.25,107.36
             116.25,107.28 116.20,107.20 116.09,107.14
             115.99,107.07 115.89,107.08 115.80,107.17
             115.45,107.38 115.26,107.53 115.22,107.62
             114.39,108.64 113.96,109.18 113.92,109.27
             112.27,111.76 111.09,114.39 110.41,117.17
             110.10,118.48 109.82,120.41 109.56,122.94
             109.52,123.62 109.60,124.44 109.81,125.41
             109.86,125.50 109.94,125.57 110.05,125.61
             110.15,125.65 110.25,125.65 110.34,125.61
             110.34,125.61 111.19,125.09 111.19,125.09
             112.39,124.04 113.44,122.94 114.31,121.78
             116.08,119.52 117.75,117.33 119.31,115.20
             119.31,115.20 120.88,113.33 120.88,113.33
             120.96,113.20 121.09,113.12 121.28,113.09
             121.48,113.07 121.66,113.11 121.84,113.19
             121.93,113.24 122.00,113.32 122.06,113.42
             122.13,113.53 122.15,113.62 122.11,113.72
             122.11,113.72 121.91,114.11 121.91,114.11
             121.91,114.11 121.12,115.20 121.12,115.20
             121.12,115.20 115.80,122.30 115.80,122.30
             114.71,123.65 113.76,124.71 112.94,125.47
             112.94,125.47 111.91,126.36 111.91,126.36
             111.34,126.79 110.73,127.00 110.08,127.00 Z
           M 126.77,124.59
           C 126.29,124.41 125.94,124.05 125.72,123.53
             125.50,122.97 125.39,122.47 125.39,122.03
             125.39,122.03 125.39,121.00 125.39,121.00
             125.39,120.30 125.44,119.78 125.53,119.44
             125.53,119.44 125.59,118.98 125.59,118.98
             125.68,118.55 125.66,118.31 125.53,118.27
             125.53,118.27 125.45,118.27 125.45,118.27
             125.33,118.27 125.16,118.46 124.94,118.84
             124.85,118.94 124.64,119.29 124.30,119.89
             124.30,119.89 122.34,123.02 122.34,123.02
             122.34,123.02 121.62,123.98 121.62,123.98
             121.32,124.33 120.91,124.50 120.39,124.50
             119.91,124.50 119.48,124.38 119.09,124.12
             118.74,123.96 118.46,123.62 118.25,123.11
             118.07,122.57 118.03,121.90 118.11,121.09
             118.46,118.79 118.66,117.53 118.70,117.31
             119.09,115.54 119.61,113.77 120.27,111.98
             120.27,111.98 120.84,110.75 120.84,110.75
             121.06,110.41 121.43,110.28 121.95,110.36
             121.95,110.36 122.34,110.56 122.34,110.56
             122.69,110.69 122.86,110.93 122.86,111.28
             122.86,111.41 122.85,111.59 122.83,111.83
             122.80,112.07 122.75,112.34 122.67,112.64
             122.45,113.73 122.30,114.46 122.20,114.84
             122.20,114.84 121.75,117.12 121.75,117.12
             121.49,118.69 121.32,119.84 121.23,120.56
             121.19,120.70 121.21,120.92 121.30,121.22
             121.30,121.22 121.36,121.28 121.36,121.28
             121.45,121.28 121.50,121.28 121.50,121.28
             121.71,121.16 121.88,120.99 122.02,120.78
             123.14,119.23 124.05,117.75 124.75,116.33
             124.75,116.33 126.31,113.62 126.31,113.62
             126.52,113.25 126.73,112.92 126.95,112.62
             127.25,112.21 127.60,112.00 128.00,112.00
             128.21,112.00 128.47,112.09 128.78,112.25
             129.08,112.37 129.23,112.55 129.23,112.80
             129.27,113.04 129.30,113.46 129.30,114.08
             129.30,114.08 128.78,117.94 128.78,117.94
             128.78,117.94 128.39,121.05 128.39,121.05
             128.39,121.05 128.39,121.92 128.39,121.92
             128.39,121.96 128.47,122.03 128.64,122.11
             128.64,122.11 128.78,122.05 128.78,122.05
             128.78,122.05 129.23,121.47 129.23,121.47
             129.23,121.47 132.09,116.77 132.09,116.77
             132.09,116.77 134.23,112.95 134.23,112.95
             134.41,112.65 134.71,112.50 135.14,112.50
             135.36,112.54 135.51,112.64 135.59,112.80
             135.69,112.95 135.69,113.11 135.59,113.28
             135.59,113.28 135.08,114.39 135.08,114.39
             135.08,114.39 133.72,116.80 133.72,116.80
             132.07,119.66 130.86,121.84 130.08,123.31
             129.98,123.48 129.81,123.74 129.56,124.09
             129.21,124.70 128.66,125.00 127.94,125.00
             127.58,125.00 127.19,124.87 126.77,124.59 Z"
        />
    </svg>
)

export default Logo